import React, { useEffect, useState } from "react";

function GDImageViewerGallery(gId, gName) {
  const [imgIds, setImgIds] = useState([]);

  const GOOGLE_API_KEY = "AIzaSyD0VaU6j51eeH4ajUkBjGtViDgR5psCDlI";
  const GOOGLE_DRIVE_URL_START =
    "https://www.googleapis.com/drive/v2/files?q=%27";
  const GOOGLE_DRIVE_URL_END = "%27+in+parents&key=";
  // const GOOGLE_DRIVE_URL_END = ("%27+in+parents+and+title+%3d+%27CCPro_BG_17A_deflect_grip_offrail%27&maxResults=1000&key=");
  const GOOGLE_DRIVE_URL2_END = "%27+in+parents+and+title+%3d+%27";
  const GOOGLE_DRIVE_URL3_END = "%27&key=";
  // eslint-disable-next-line
  const GOOGLE_DRIVE_IMG_URL = "https://drive.google.com/uc?export=view&id=";

  console.log("Data ID", gId);
  console.log("Data NAME", gName);
  useEffect(() => {
    loadFolderData();
  }, []);

  async function loadFolderData() {
    await fetch(
      GOOGLE_DRIVE_URL_START +
        gId +
        GOOGLE_DRIVE_URL2_END +
        gName +
        GOOGLE_DRIVE_URL3_END +
        GOOGLE_API_KEY
    )
      .then((response) => response.json())
      .then((jsonResp) => {
        console.log("jsonResp.items", jsonResp.items);
        setImgIds(jsonResp.items);
      });
  }

  return imgIds;
}

export default GDImageViewerGallery;
