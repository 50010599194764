import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import emailjs from "emailjs-com";
import { formatPrice } from "../../utils";
import Button from "../Button";
import GDImageViewerSummary from "../../libs/GDImageViewerSummary";
import GDVideoViewerSummary from "../../libs/GDVideoViewerSummary";
// Styles
import "./Summary.css";
import { Fade } from "react-awesome-reveal";
// https://leanylabs.com/blog/form-validation-in-react/
/*
 * TODO
 *
 * Requirements:
 * - use React hooks if needed
 * - use performance optimization if needed
 *
 */
const rootStyle = document.querySelector(":root");

const Summary = ({ config = null, models = null, totalPrice = 0 }) => {
  const [inputFields, setInputFields] = useState({
    name: "",
    email: "",
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const selectedModel = models?.find((model) => model?.key === config?.model);
  const selectedType = selectedModel?.types?.find(
    (type) => type.value === config?.car_type
  );
  const selectedColor = selectedModel?.colors?.find(
    (color) => color.value === config?.color
  );
  const selectedWheels = selectedModel?.wheels?.find(
    (wheels) => wheels.value === config?.wheels
  );
  const selectedInteriorColor = selectedModel?.interiorColors?.find(
    (interiorColor) => interiorColor.value === config?.interior_color
  );

  const selectedStyling = selectedModel?.styling?.find(
    (styling) => styling.value === config?.styling
  );
  const selectedStorage = selectedModel?.storage?.find(
    (storage) => storage.value === config?.storage
  );
  const selectedRunningBords = selectedModel?.runningBords?.find(
    (runningBords) => runningBords.value === config?.runningBords
  );
  const selectedTowings = selectedModel?.towings?.find(
    (towings) => towings.value === config?.towings
  );
  const galleryId = `CCPro_${selectedColor?.value}_${selectedWheels?.value}`;
  const selectedGallery = selectedModel?.gallery?.find(
    (gallery) => gallery.value === galleryId
  );

  // Get Featured Image
  const gName = `CCPro_${selectedColor?.value}_${selectedWheels?.value}_${selectedStyling?.value}_${selectedStorage?.value}_${selectedRunningBords?.value}`;
  const gId = "1GYvhKHLFcmb6XH3PMTRNRkU3B-8ANe7Y";
  const gMainImage = GDImageViewerSummary(gId, gName);

  // Get Video ID
  const gVideoId = "1fnOpYRHZLweNxEjZXUdjzZhY5pLsYdfM";
  const gVideoName = `CCPro_${selectedColor?.value}_${selectedWheels?.value}_${selectedStyling?.value}_${selectedStorage?.value}.mp4`;
  const gVideo = GDVideoViewerSummary(gVideoId, gVideoName);

  const URL = `https://ldrive.inkers.cloud/gallery?vId=${gVideo[0]?.id}&paint=${selectedColor?.value}&wheel=${selectedWheels?.value}&styling=${selectedStyling?.value}&storage=${selectedStorage?.value}&board=${selectedRunningBords?.value}&towing=${selectedTowings?.value}&interior=${selectedInteriorColor?.value}`;

  var templateParams = {
    from_name: inputFields.name,
    from_email: inputFields.email,
    model: selectedModel?.name,
    model_price: formatPrice(selectedType?.price),
    paint: selectedColor?.label,
    paint_price: formatPrice(selectedColor?.price),
    styling: selectedStyling?.label,
    styling_price: formatPrice(selectedStyling?.price),
    wheels: selectedWheels?.label,
    wheels_price: formatPrice(selectedWheels?.price),
    storage: selectedStorage?.label,
    storage_price: formatPrice(selectedStorage?.price),
    bords: selectedRunningBords?.label,
    bords_price: formatPrice(selectedRunningBords?.price),
    interior: selectedInteriorColor?.label,
    interior_price: formatPrice(selectedInteriorColor?.price),
    towings: selectedTowings?.label,
    towings_price: formatPrice(selectedTowings?.price),
    total: formatPrice(totalPrice),
    gallery_url: URL,
    image_url: `http://drive.google.com/uc?export=view&id=${gMainImage[2]?.id}`,
  };
  //   var queryParams = {
  //     paint: selectedColor?.value,
  //     styling: selectedStyling?.value,
  //     wheels: selectedWheels?.value,
  //     storage: selectedStorage?.value,
  //     bords: selectedRunningBords?.value,
  //     interior: selectedInteriorColor?.value,
  //     towings: selectedTowings?.value, //CCPro_RA_17B_nstyle_nstorage
  //     nnn: `CCPro_${selectedColor?.value}_${selectedWheels?.value}_${selectedStyling?.value}_${selectedStorage?.value}`,
  //   };
  console.log("URL", URL);
  const handleChange = (e) => {
    if (e.target.name === "name") {
      const el1 = document.querySelector('[name="name"]');
      if (el1.value.length > 0) {
        el1.classList.add("filled");
      } else {
        el1.classList.remove("filled");
      }
    }
    if (e.target.name === "email") {
      const el2 = document.querySelector('[name="email"]');
      if (el2.value.length > 0) {
        el2.classList.add("filled");
      } else {
        el2.classList.remove("filled");
      }
    }

    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };
  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.name.length < 2) {
      errors.name = "Name is too short";
    }
    if (inputValues.email.length < 6) {
      errors.email = "Email is too short";
    }
    return errors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const element = document.getElementById("contact-footer");
    if (element) {
      setTimeout(function () {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 100);
      setShowLoader(true);
      setErrors(validateValues(inputFields));
      setSubmitting(true);
      setTimeout(() => setShowLoader(false), 1500);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitting) {
      finishSubmit();
    }
  }, [errors]);
  const finishSubmit = () => {
    emailjs
      .send(
        "service_fzzb9by",
        "template_09bzdtk",
        templateParams,
        "NEJ4Pwzi2MS-Q46hc"
      )
      .then(
        (result) => {
          setShowSuccess(true);
          //   setTimeout(() => setShowSuccess(false), 3500);
          emailjs
            .send(
              "service_fzzb9by",
              "template_vnqk2jd",
              templateParams,
              "NEJ4Pwzi2MS-Q46hc"
            )
            .then(
              (result) => {
                setTimeout(() => window.location.reload(), 3000);
                //window.location.reload();
              },
              (error) => {
                console.log(error.text);
              }
            );
          //window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
    // setTimeout(() => setShowSuccess(true), 1500);
  };
  return (
    <div className="summary">
      <h2>Your Configuration</h2>
      {/* <p className="summary-edd">Estimated delivery: 5-9 weeks</p> */}
      <div className="summary-content">
        <p>Summary</p>
        <Fade cascade damping={0.1} triggerOnce={true}>
          <ul>
            <li>
              <h5>Model</h5>
              <div className="summary-content-row">
                <span>{selectedModel?.name}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedType?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Paint</h5>
              <div className="summary-content-row">
                <span>{selectedColor?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedColor?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Styling</h5>
              <div className="summary-content-row">
                <span>{selectedStyling?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedStyling?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Wheels</h5>
              <div className="summary-content-row">
                <span>{selectedWheels?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedWheels?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Storage</h5>
              <div className="summary-content-row">
                <span>{selectedStorage?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedStorage?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Running Bords</h5>
              <div className="summary-content-row">
                <span>{selectedRunningBords?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedRunningBords?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Interior Color</h5>
              <div className="summary-content-row">
                <span>{selectedInteriorColor?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedInteriorColor?.price)}
                </span>
              </div>
            </li>
            <li>
              <h5>Towing</h5>
              <div className="summary-content-row">
                <span>{selectedTowings?.label}</span>
                <span className="summary-content-row-price">
                  {formatPrice(selectedTowings?.price)}
                </span>
              </div>
            </li>
          </ul>
        </Fade>
        <p>
          <span>
            Total Cost <span className="text-weight-400">(MSRP)</span>
          </span>
          <span className="text-primary">{formatPrice(totalPrice)}</span>
        </p>
      </div>
      <section id="contact-footer" className="summary-form">
        {!showSuccess ? (
          <form
            id="formRequestQuote"
            onSubmit={handleSubmit}
            autoComplete="off"
          >
            <h6>REQUEST A QUOTE</h6>
            <div className="input-field">
              <input
                name="name"
                id="name"
                type="text"
                value={inputFields.name}
                onChange={handleChange}
                className="invalid-input"
              />
              <label className="label-text">Name*</label>
            </div>

            <div className="input-field input-field-email">
              <input
                name="email"
                id="email"
                type="email"
                value={inputFields.email}
                onChange={handleChange}
                className="invalid-input"
              />
              <label className="label-text">Email*</label>
            </div>
            {errors.name ? (
              <p className="missing-fields error">
                Name should be at least 2 characters long
              </p>
            ) : null}
            {errors.email ? (
              <p className="missing-fields error">
                Email should be at least 6 characters long
              </p>
            ) : null}

            <div className="submit-btn-footer">
              <Button
                text="Get Your Quote"
                className="footer-nav-2-button btn-getQuote"
                loading={showLoader}
                disabled={showLoader}
                id="GetQuoteBtn"
              />
            </div>
          </form>
        ) : (
          <div className="success-msg">
            <p></p>
            <h6>THANK YOU</h6>
            <p>You will receive the Quote Summary at your email.</p>
          </div>
        )}
      </section>
    </div>
  );
};

Summary.propTypes = {
  config: PropTypes.object,
  models: PropTypes.array,
  totalPrice: PropTypes.number,
};

export default Summary;
