import React from "react";
import { models, initialConfig } from "../../../data";
// Styles
import "./Home.css";
// Components
import Menu from "../../Menu";
import MenuSub from "../../MenuSub";
import Footer from "../../Footer";
import Settings from "../../Settings";
import Summary from "../../Summary";
import Viewer from "../../Viewer";
/*
 * TODO: Refactor App as a functional component
 *
 * Requirements:
 * - Compute total price using React hooks only when config or selectedModel change
 * - Create a custom hook to use localStorage to store the current step and config
 * - Switch from setState to the useLocalStorage hook
 * - Use function closures instead of this for callbacks and event handlers
 * - App logic and behavior should remain the same
 *
 */

class Home extends React.Component {
  state = {
    currentStep: 0,
    currentSubStep: 0,
    config: initialConfig?.["s"] ?? null,
    annotationId: 0,
    isStudio: 1,
  };

  get selectedModel() {
    document.body.classList.remove("gallery-layout");
    return models.find((model) => model?.key === this.state.config?.model);
  }

  get steps() {
    return [
      {
        name: "exterior",
        settings: [
          {
            label: "Select color",
            parent: "root",
            type: "text",
            prop: "color",
            name: "Paint",
            options: this.selectedModel?.colors ?? [],
          },
          {
            name: "Styling",
            label: "Select styling",
            parent: "root",
            type: "text",
            multiple: true,
            prop: "styling",
            options: this.selectedModel?.styling ?? [],
          },

          {
            name: "Wheels",
            parent: "root",
            label: "Select wheels",
            type: "text",
            prop: "wheels",
            options: this.selectedModel?.wheels ?? [],
          },
          {
            name: "Storage",
            parent: "root",
            label: "Select storage",
            type: "text",
            prop: "storage",
            options: this.selectedModel?.storage ?? [],
          },
          {
            name: "Running Bords",
            parent: "root",
            label: "Select running bords",
            type: "text",
            prop: "runningBords",
            options: this.selectedModel?.runningBords ?? [],
          },
        ],
      },
      {
        name: "interior",
        settings: [
          {
            name: "Color",
            parent: "root",
            label: "Select premium interior",
            type: "text",
            prop: "interior_color",
            options: this.selectedModel?.interiorColors ?? [],
          },
        ],
      },
      {
        name: "Towing",
        settings: [
          {
            name: "Towing",
            parent: "root",
            label: "Select Towing",
            type: "text",
            prop: "towings",
            options: this.selectedModel?.towings ?? [],
          },
        ],
      },
      {
        name: "summary",
        sub: [],
      },
    ];
  }

  get totalPrice() {
    const basePrice =
      this.selectedModel?.types?.find(
        (type) => type.value === this.state.config?.car_type
      )?.price ?? 0;
    const colorPrice =
      this.selectedModel?.colors?.find(
        (color) => color.value === this.state.config?.color
      )?.price ?? 0;
    const stylingPrice =
      this.selectedModel?.styling?.find(
        (styling) => styling.value === this.state.config?.styling
      )?.price ?? 0;
    const wheelsPrice =
      this.selectedModel?.wheels?.find(
        (wheels) => wheels.value === this.state.config?.wheels
      )?.price ?? 0;
    const storagePrice =
      this.selectedModel?.storage?.find(
        (storage) => storage.value === this.state.config?.storage
      )?.price ?? 0;
    const runningBordsPrice =
      this.selectedModel?.runningBords?.find(
        (runningBords) => runningBords.value === this.state.config?.runningBords
      )?.price ?? 0;
    const interiorColorPrice =
      this.selectedModel?.interiorColors?.find(
        (interiorColor) =>
          interiorColor.value === this.state.config?.interior_color
      )?.price ?? 0;
    const towingsPrice =
      this.selectedModel?.towings?.find(
        (towing) => towing.value === this.state.config?.towings
      )?.price ?? 0;
    const interiorLayoutPrice =
      this.selectedModel?.interiorLayouts?.find(
        (interiorLayout) =>
          interiorLayout.value === this.state.config?.interior_layout
      )?.price ?? 0;

    return (
      basePrice +
      colorPrice +
      stylingPrice +
      wheelsPrice +
      storagePrice +
      runningBordsPrice +
      interiorColorPrice +
      towingsPrice +
      interiorLayoutPrice
    );
  }

  goToStep = (step) => {
    this.setState({ currentStep: step });
    this.setState({ currentSubStep: 0 });
    if (step === 2) {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        6,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            window.console.log("Going to annotation", index + 1);
          }
        }
      );
    } else if (step === 3) {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        7,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            window.console.log("Going to annotation", index + 1);
          }
        }
      );
    } else {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        step,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            window.console.log("Going to annotation", index + 1);
          }
        }
      );
    }

    //this.state.config?.sketchFab.apiRef.current.setCameraEasing("easeLinear");
  };
  goToSummary = () => {
    this.setState({ currentStep: 3 });
    this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
      7,
      { preventCameraAnimation: false, preventCameraMove: false },
      function (err, index) {
        if (!err) {
          window.console.log("Going to annotation", index + 1);
        }
      }
    );
    // this.state.config?.sketchFab.apiRef.current.setCameraEasing("easeLinear");
  };
  //currentSubStep
  goToSubStep = (step) => {
    this.setState({ currentSubStep: step });
    if (this.state.currentStep === 0) {
      if (step === 0) {
        this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
          0,
          { preventCameraAnimation: false, preventCameraMove: false },
          function (err, index) {
            if (!err) {
              window.console.log("Going to annotation Sup", index + 1);
            }
          }
        );
      } else if (step === 1) {
        this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
          3,
          { preventCameraAnimation: false, preventCameraMove: false },
          function (err, index) {
            if (!err) {
              window.console.log("Going to annotation Sup", index + 1);
            }
          }
        );
      } else if (step === 2) {
        this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
          2,
          { preventCameraAnimation: false, preventCameraMove: false },
          function (err, index) {
            if (!err) {
              window.console.log("Going to annotation Sup", index + 1);
            }
          }
        );
      } else if (step === 3) {
        this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
          4,
          { preventCameraAnimation: false, preventCameraMove: false },
          function (err, index) {
            if (!err) {
              window.console.log("Going to annotation Sup", index + 1);
            }
          }
        );
      } else if (step === 4) {
        this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
          5,
          { preventCameraAnimation: false, preventCameraMove: false },
          function (err, index) {
            if (!err) {
              window.console.log("Going to annotation Sup", index + 1);
            }
          }
        );
      } else {
        this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
          1 + step,
          { preventCameraAnimation: false, preventCameraMove: false },
          function (err, index) {
            if (!err) {
              window.console.log("Going to annotation Sup", index + 1);
            }
          }
        );
      }

      // this.state.config?.sketchFab.apiRef.current.setCameraEasing("easeLinear");
    }
  };

  goToPrevStep = () => {
    this.setState((prevState) => {
      const newStep =
        prevState.currentStep > 0
          ? prevState.currentStep - 1
          : prevState.currentStep;
      return { currentStep: newStep };
    });
    this.setState({ currentSubStep: 0 });
    if (this.state.currentStep === 1) {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        0,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            //window.console.log("Going to annotation", index + 1);
          }
        }
      );
    } else if (this.state.currentStep === 2) {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        1,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            //window.console.log("Going to annotation", index + 1);
          }
        }
      );
    } else if (this.state.currentStep === 3) {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        6,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            window.console.log("Going to annotation", index + 1);
          }
        }
      );
    } else {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        7,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            window.console.log("Going to annotation", index + 1);
          }
        }
      );
    }

    this.state.config?.sketchFab.apiRef.current.setCameraEasing("easeLinear");
  };
  // goToStudio
  goToNextStep = () => {
    this.setState((prevState) => {
      const newStep =
        prevState.currentStep < this.steps.length - 1
          ? prevState.currentStep + 1
          : prevState.currentStep;
      return { currentStep: newStep };
    });
    this.setState({ currentSubStep: 0 });
    console.log("this.state.currentStep", this.state.currentStep);
    if (this.state.currentStep === 0) {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        1,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            //window.console.log("Going to annotation", index + 1);
          }
        }
      );
    } else if (this.state.currentStep === 1) {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        6,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            //window.console.log("Going to annotation", index + 1);
          }
        }
      );
    } else if (this.state.currentStep === 2) {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        7,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            window.console.log("Going to annotation", index + 1);
          }
        }
      );
    } else {
      this.state.config?.sketchFab?.apiRef.current.gotoAnnotation(
        0,
        { preventCameraAnimation: false, preventCameraMove: false },
        function (err, index) {
          if (!err) {
            window.console.log("Going to annotation", index + 1);
          }
        }
      );
    }

    this.state.config?.sketchFab.apiRef.current.setCameraEasing("easeLinear");
  };

  goToStudio = () => {
    this.state.config?.sketchFab.apiRef.current.hide(1593);
    this.state.config?.sketchFab.apiRef.current.show(1640);
    this.setState({ isStudio: 1 });
  };
  goToStudio2 = () => {
    this.state.config?.sketchFab.apiRef.current.hide(1640);
    this.state.config?.sketchFab.apiRef.current.show(1593);

    this.setState({ isStudio: 0 });
  };

  handleChangeModel = (model) => {
    this.setState({ config: initialConfig[model] });
  };

  handleOnSelectOption = (prop, value) => {
    if (prop === "model") {
      this.handleChangeModel(value);
    } else {
      this.setState((prevState) => ({
        config: {
          ...prevState.config,
          [prop]: value,
        },
      }));
    }
  };

  handleOnChangeOption = (prop, value) => {
    this.setState((prevState) => ({
      config: {
        ...prevState.config,
        [prop]: value,
      },
    }));
  };

  render() {
    const isFirstStep = this.state.currentStep === 0;
    const isLastStep = this.state.currentStep === this.steps.length - 1;
    const isStudioActive = this.state.isStudio;
    return (
      <div className="app">
        <Menu
          items={this.steps.map((step) => step.name)}
          selectedItem={this.state.currentStep}
          onSelectItem={this.goToStep}
        />
        {this.steps[this.state.currentStep]?.settings ? (
          <MenuSub
            items={this.steps[this.state.currentStep]?.settings.map(
              (step) => step
            )}
            selectedItem={this.state.currentSubStep}
            onSelectItem={this.goToSubStep}
          />
        ) : null}

        <main className="app-content">
          <Viewer
            config={this.state.config}
            models={models}
            showAllModels={isFirstStep}
            showSpecs={!isLastStep}
            onChangeModel={this.handleChangeModel}
          />
          {isLastStep ? (
            <Summary
              config={this.state.config}
              models={models}
              totalPrice={this.totalPrice}
            />
          ) : (
            <Settings
              config={this.state.config}
              models={models}
              settings={this.steps[this.state.currentStep].settings}
              onSelectOption={this.handleOnSelectOption}
              selectedSubItem={this.state.currentSubStep}
            />
          )}
        </main>
        <Footer
          totalPrice={this.totalPrice}
          disablePrev={isFirstStep}
          disableNext={isLastStep}
          onClickPrev={this.goToPrevStep}
          onClickNext={this.goToNextStep}
          onClickStudio={this.goToStudio}
          onClickStudio2={this.goToStudio2}
          activeStudioBtn={isStudioActive}
          nextStep={this.steps[this.state.currentStep + 1]?.name}
          prevStep={this.steps[this.state.currentStep - 1]?.name}
          showQuote={isLastStep}
          onClickSummary={this.goToSummary}
        />
      </div>
    );
  }
}

export default Home;
