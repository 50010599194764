import React, { useState } from "react";

import Menu from "../../Menu";
import { Player } from "video-react";
import Fancybox from "./Fancybox";
import GDImageViewer from "../../../libs/GDImageViewer";
import GDImageViewerGallery from "../../../libs/GDImageViewerGallery";
import "../../../libs/GDImageViewer.css";

// Styles
import "video-react/dist/video-react.css";
import "./Gallery.css";

const Gallery = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const paint = queryParameters.get("paint");
  const styling = queryParameters.get("styling");
  const wheel = queryParameters.get("wheel");
  const storage = queryParameters.get("storage");
  const board = queryParameters.get("board");
  const interior = queryParameters.get("interior");
  const towing = queryParameters.get("towing");
  const dirId = queryParameters.get("dirId");
  const vId = queryParameters.get("vId");
  document.body.classList.add("gallery-layout");

  const [qParamsVideo, setQParamsVideo] = useState({});

  //CCPro_EXT-BR_TG_17A_deflect_net_tuberail.jpeg
  const fullImageNameImage = `CCPro_${paint}_${wheel}_${styling}_${storage}_${board}`;
  const fullImageNameVideo = `CCPro_${paint}_${wheel}_${styling}_${storage}_${board}`;

  const gNameForInterior = `CCPro_INT_${paint}_${interior}.jpeg`; //
  const gIdForInterior = "1PS_sft0Pp2Dp7cdCL3Iy9yoKVsN4YyM4";
  const interiorImage = GDImageViewerGallery(gIdForInterior, gNameForInterior);
  console.log("interiorImage", interiorImage);

  const configs = {
    gkey: "AIzaSyD0VaU6j51eeH4ajUkBjGtViDgR5psCDlI",
    dirId: dirId,
    name: fullImageNameImage,
    interiorImage: interiorImage,
    options: [
      {
        style: {},
        onClick: {
          modal: false,
          newWindow: false,
        },

        exclude: {},
        attachClass: {
          "CCPro_EXT-BR_BG_17A_deflect_bstep_offrail.jpeg": "test",
        },
        attachId: {
          "CCPro_EXT-BR_BG_17A_deflect_bstep_offrail.jpeg": "test2",
        },
        hover: false,
      },
    ],
  };

  return (
    <div className="gallery-page">
      <Menu items={[]} selectedItem={null} onSelectItem={null} />
      <div className="container">
        <div className="container-photos">
          <h2> Photos </h2>
          <Fancybox
            options={{
              Carousel: {
                infinite: true,
              },
              Toolbar: {
                display: {
                  left: ["download"],
                  middle: ["prev", "infobar", "next"],
                  right: ["close"],
                },
              },
            }}
          >
            <GDImageViewer data={configs}></GDImageViewer>
          </Fancybox>
        </div>
        {vId ? (
          <div className="container-video">
            <h2>Video</h2>
            <div className="video-container">
              <div className="video-container-video">
                <iframe
                  src={`https://drive.google.com/file/d/${vId}/preview`}
                  title="Video"
                  width="100%"
                  height="100%"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="container-disclaimer">
        <div className="disclaimer">
          <p className="disclaimer-text">
            Lunas Inc. hereby explicitly asserts that it is not, in any
            capacity, an authorized legal car distributor. The content,
            materials and products displayed on this platform, including but not
            limited to images, specifications, prices and descriptions of
            vehicles or related products, are provided solely for the purpose of
            demonstration.
            <br />
            <br />
            All inquiries regarding the purchase, availability, or service of
            vehicles or products should be directed to an authorized car dealer
            or a legally recognized distributor. Lunas Inc. disclaims any
            responsibility or liability for any reliance placed on the content
            presented on this platform for commercial or legal purposes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
