import React, { useEffect, useState } from "react";

function GDImageViewerSummary(gId, gName) {
  const [imgIds, setImgIds] = useState([]);

  const GOOGLE_API_KEY = "AIzaSyD0VaU6j51eeH4ajUkBjGtViDgR5psCDlI";
  const GOOGLE_DRIVE_URL_START =
    "https://www.googleapis.com/drive/v2/files?q=%27";
  const GOOGLE_DRIVE_URL_END = "%27+in+parents&key=";
  const GOOGLE_DRIVE_URL2_END = "%27+in+parents+and+title+%3d+%27";
  const GOOGLE_DRIVE_URL3_END = "%27&key=";

  console.log("Data ID", gId);
  console.log("Data NAME", gName);
  useEffect(() => {
    loadFolderData();
  }, []);

  async function loadFolderData() {
    await fetch(
      GOOGLE_DRIVE_URL_START +
        gId +
        GOOGLE_DRIVE_URL2_END +
        gName +
        GOOGLE_DRIVE_URL3_END +
        GOOGLE_API_KEY
    )
      .then((response) => response.json())
      .then((jsonResp) => {
        // console.log("jsonResp.items", jsonResp.items);
        loadData(jsonResp.items[0].id);
      });
  }
  async function loadData(driveId) {
    await fetch(
      GOOGLE_DRIVE_URL_START + driveId + GOOGLE_DRIVE_URL_END + GOOGLE_API_KEY
    )
      .then((response) => response.json())
      .then((jsonResp) => {
        setImgIds(jsonResp.items);
      });
  }
  return imgIds;
}

export default GDImageViewerSummary;
