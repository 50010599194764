import React from "react";
import PropTypes from "prop-types";
// Styles
import "./Menu.css";
// Icons
import { Fade, Slide } from "react-awesome-reveal";

/*
 * TODO: Refactor Menu as a functional component
 *
 * Requirements:
 * - Create a custom hook to implement dark mode named useDarkMode
 * - Switch from setState to the useDarkMode hook
 * - Use function closures instead of this for callbacks and event handlers
 * - Menu logic and behavior should remain the same
 *
 */
class Menu extends React.Component {
  state = {
    darkMode: true,
  };

  handleOnChangeMode = () => {
    this.setState((prevState) => ({
      ...prevState,
      darkMode: !prevState.darkMode,
    }));
  };

  render() {
    const brandLogoSM = `${process.env.PUBLIC_URL}/logo-sm.svg`;
    // const brandLogo = this.state.darkMode
    //   ? `${process.env.PUBLIC_URL}/logo_white.svg`
    //   : `${process.env.PUBLIC_URL}/logo.svg`;

    return (
      <div>
        <div className="top-bar-sm">
          <a href="/" className="logo">
            <Slide>
              <img src={brandLogoSM} alt="L'Drive" />
            </Slide>
          </a>
        </div>
        <div className="menu-container">
          <a href="/" className="logo" title="L'Drive">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="84"
              height="19"
              viewBox="0 0 84 19"
              fill="none"
            >
              <path
                d="M8.79443 14.722H3.33687V0.364908C3.33687 0.173251 3.17814 0.0180664 2.9839 0.0180664H0.353691C0.158001 0.0180664 0 0.173251 0 0.364908V15.0696V17.6641C0 17.8564 0.158001 18.0131 0.353691 18.0131H2.9839H8.79443C8.9894 18.0131 9.14812 17.8564 9.14812 17.6641V15.0696C9.14812 14.8772 8.9894 14.722 8.79443 14.722Z"
                fill="#FEFEFE"
              />
              <path
                d="M73.0544 17.4853V11.6047V6.4214V0.767531V0.349891C73.0544 0.164671 73.2066 0.0130615 73.3958 0.0130615H73.7973H74.3801H77.1683H81.2198H83.0237C83.2028 0.0130615 83.3492 0.156089 83.3492 0.334158V2.71914C83.3492 2.89649 83.2028 3.03952 83.0237 3.03952H81.2198H77.1683H76.3275V7.42331H81.2198V7.43618H82.0569C82.2316 7.43618 82.3751 7.57635 82.3751 7.74941V10.0836C82.3751 10.2574 82.2316 10.3976 82.0569 10.3976H81.2198V10.3997H76.3275V14.9351H77.0646H81.2198H83.0187C83.2006 14.9351 83.3492 15.081 83.3492 15.2612V17.6862C83.3492 17.8672 83.2006 18.0131 83.0187 18.0131H81.2198H77.0646H74.3801H73.7973H73.3958C73.2066 18.0131 73.0544 17.8615 73.0544 17.6755V17.4853Z"
                fill="#FEFEFE"
              />
              <path
                d="M13.0809 2.06622C13.0809 3.19971 12.1481 4.11938 11.0008 4.11938C9.85198 4.11938 8.91992 3.19971 8.91992 2.06622C8.91992 0.932013 9.85198 0.0130615 11.0008 0.0130615C12.1481 0.0130615 13.0809 0.932013 13.0809 2.06622Z"
                fill="#FEFEFE"
              />
              <path
                d="M27.1769 6.89552C27.1769 5.63903 26.9522 4.64713 26.5035 3.92127C26.0549 3.1954 25.2569 2.83283 24.1082 2.83283H21.928V15.1933H24.135C25.2838 15.1933 26.0774 14.8965 26.5173 14.3036C26.9565 13.7101 27.1769 12.8827 27.1769 11.82V6.89552ZM19.1775 18.008C18.9869 18.008 18.8325 17.855 18.8325 17.6676C18.8325 11.7334 18.8325 6.29266 18.8325 0.357739C18.8325 0.170373 18.9869 0.017334 19.1775 0.017334C20.9735 0.017334 22.7695 0.017334 24.5655 0.017334C25.8215 0.017334 26.8986 0.247608 27.7958 0.708156C28.6931 1.16799 29.3476 1.92961 29.7607 2.99159C30.0115 3.64665 30.16 4.4569 30.2043 5.42162C30.2492 6.38706 30.2717 6.98706 30.2717 8.20851C30.2717 9.71316 30.263 11.0018 30.2448 12.0724C30.2267 13.1437 30.0745 14.0598 29.7875 14.8214C29.5541 15.4407 29.249 15.9542 28.8721 16.3618C28.4952 16.7687 28.0647 17.0919 27.5806 17.3308C27.0957 17.5697 26.5666 17.7427 25.9926 17.8486C25.4178 17.9551 24.8257 18.008 24.2162 18.008C22.5361 18.008 20.8568 18.008 19.1775 18.008Z"
                fill="#FEFEFE"
              />
              <path
                d="M63.2432 12.5523L63.2425 12.5508H63.2418L63.2411 12.5494H63.2403L63.2396 12.548L63.2389 12.5473V12.5466L63.2382 12.5458L63.2374 12.5451V12.5444H63.2367L63.236 12.543L63.2353 12.5423V12.5415L63.2345 12.5401L63.2338 12.5394V12.5387L63.2331 12.5373L63.2324 12.5365L60.0723 0.268365C60.0318 0.116756 59.8911 0.0130615 59.7339 0.0130615H57.1725C57.0602 0.0130615 56.9573 0.062406 56.8906 0.149653C56.8239 0.235469 56.8021 0.349176 56.8333 0.454301L61.777 17.7585C61.777 17.762 61.7792 17.7649 61.7821 17.7678C61.7937 17.8035 61.8082 17.8379 61.8314 17.8672C61.8343 17.8715 61.8364 17.8736 61.8415 17.8772C61.8633 17.9065 61.8886 17.9344 61.9227 17.9537C61.9227 17.9566 61.9249 17.9566 61.9249 17.9566C61.9582 17.9773 61.9944 17.993 62.03 18.0023C62.0343 18.0023 62.0372 18.0038 62.0394 18.0045C62.0633 18.0095 62.0894 18.0131 62.1155 18.0131H64.4159C64.442 18.0131 64.4681 18.0095 64.492 18.0045C64.4949 18.0038 64.4971 18.0023 64.5014 18.0023C64.5377 17.993 64.5739 17.9773 64.6065 17.9566C64.6065 17.9566 64.6094 17.9566 64.6094 17.9537C64.6428 17.9344 64.6689 17.9065 64.6899 17.8772C64.695 17.8736 64.6979 17.8715 64.7 17.8672C64.7232 17.8379 64.7377 17.8035 64.75 17.7678C64.7522 17.7649 64.7551 17.762 64.7551 17.7585L69.6988 0.454301C69.73 0.349176 69.7082 0.235469 69.6416 0.149653C69.5749 0.062406 69.472 0.0130615 69.3596 0.0130615H66.7983C66.641 0.0130615 66.5004 0.116756 66.4598 0.268365L63.299 12.5365V12.5373H63.2983V12.5387L63.2976 12.5394V12.5401H63.2969V12.5415H63.2961V12.5423L63.2954 12.543V12.5444H63.2947V12.5451H63.294V12.5458L63.2932 12.5466L63.2925 12.5473L63.2918 12.548L63.2911 12.5494H63.2903L63.2896 12.5508H63.2889L63.2882 12.5523C63.2824 12.558 63.2744 12.5616 63.2657 12.5616C63.2577 12.5616 63.2498 12.558 63.2432 12.5523Z"
                fill="#FEFEFE"
              />
              <path
                d="M39.817 10.5992H37.6978C37.6978 12.9585 37.6978 15.3177 37.6978 17.6769C37.6978 17.8593 37.5477 18.0081 37.3615 18.0081H34.8646C34.6784 18.0081 34.5283 17.8593 34.5283 17.6769C34.5283 11.8994 34.5283 6.12176 34.5283 0.34417C34.5283 0.16181 34.6784 0.0130615 34.8646 0.0130615C36.7222 0.0130615 38.5791 0.0130615 40.4367 0.0130615C41.1905 0.0130615 41.8928 0.121762 42.5429 0.338449C42.8698 0.44715 43.1727 0.583026 43.4532 0.746792C43.7337 0.909844 43.9917 1.1015 44.2265 1.31962C44.4614 1.53845 44.6708 1.78446 44.8542 2.05692C45.0376 2.32868 45.1956 2.62832 45.3275 2.95442C45.4601 3.28124 45.5594 3.63452 45.6254 4.01425C45.6906 4.39328 45.7239 4.79876 45.7239 5.22927C45.7239 5.79924 45.6623 6.33201 45.5391 6.82617C45.4159 7.32176 45.2311 7.77802 44.9839 8.19566C44.7368 8.61473 44.4295 8.98374 44.062 9.30198C43.7199 9.59876 43.3249 9.85263 42.8785 10.0622L45.7196 17.3408C46.0204 18.1118 45.9283 18.0081 45.321 18.0081L43.0836 18.0066C42.6581 18.0066 42.503 17.9437 42.3871 17.6255L39.817 10.5992ZM42.6067 5.22927C42.6067 4.8245 42.5545 4.47266 42.4508 4.17516C42.3487 3.88338 42.1965 3.6431 41.9935 3.45573C41.7899 3.26622 41.5405 3.12391 41.2448 3.02879C40.9455 2.93297 40.5983 2.88434 40.204 2.88434H37.6978V7.75371H40.1779C40.9252 7.75371 41.5173 7.54274 41.9529 7.12009C42.1696 6.91127 42.332 6.65025 42.4407 6.33773C42.5516 6.02164 42.6067 5.65192 42.6067 5.22927Z"
                fill="#FEFEFE"
              />
              <path
                d="M53.4775 0.362048C53.4775 0.169677 53.3202 0.0130615 53.1238 0.0130615H50.4965C50.3001 0.0130615 50.1428 0.169677 50.1428 0.362048C50.1428 6.12891 50.1428 11.8965 50.1428 17.6641V17.6734L50.1435 17.682V17.6912L50.1443 17.6998L50.1457 17.7084L50.1472 17.717L50.1486 17.7256V17.7263L50.1501 17.7342V17.7349L50.1515 17.7427L50.1537 17.7513L50.1566 17.7592V17.7599L50.1588 17.7678L50.1617 17.7756C50.1907 17.8622 50.2537 17.9337 50.3349 17.9744L50.3508 17.9816V17.9823L50.3581 17.9852L50.3661 17.9887L50.3747 17.9916L50.3827 17.9945L50.3907 17.9973L50.3994 17.9995L50.4081 18.0016L50.4161 18.0038L50.4248 18.0059L50.4335 18.0073L50.4422 18.0088L50.4509 18.0102L50.4603 18.0109L50.469 18.0116L50.4777 18.0123H50.4871L50.4965 18.0131H53.1238C53.3202 18.0131 53.4775 17.8557 53.4775 17.6641C53.4775 11.8965 53.4775 6.12891 53.4775 0.362048Z"
                fill="#FEFEFE"
              />
            </svg>
          </a>
          <Fade cascade damping={0.1}>
            <ul className="menu-nav">
              {this.props.items && this.props.items.length === 0 ? (
                <h1 className="gallery-title">Your Configurator</h1>
              ) : null}
              {this.props.items.map((item, i) => (
                <li
                  key={item}
                  onClick={() => this.props.onSelectItem(i)}
                  className={this.props.selectedItem === i ? "selected" : null}
                >
                  <h2>{item}</h2>
                </li>
              ))}
            </ul>
          </Fade>
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  selectedItem: PropTypes.number,
  onSelectItem: PropTypes.func,
};

export default Menu;
