import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Gallery from "../Pages/Gallery";
import { CleanConsole } from "@eaboy/clean-console";

const configOptions = {
  // excludeMethods?: ConsoleMethods[],
  clearOnInit: true,
  // debugLocalStoregeKey?: string,
  initialMessages: [
    {
      message: "Created by Black Ink: https://blackink.agency",
      style:
        "border:2px solid gray; padding:5px; font-family:monospace; font-size:11px;",
    },
  ],
};
function App() {
  CleanConsole.init(configOptions);
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/gallery" element={<Gallery />} />
      </Routes>
    </Router>
  );
}

export default App;
