import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "./Model.css";
import Preloader from "../Preloader";

// Our wonderful nissan model
//5d743cb5f67c4948bb66dcdfb0b2cbe8 With Logo
//64df7897d5b5412eadedad796386389a Without Logo
const MODEL_UID = "64df7897d5b5412eadedad796386389a";
const rootStyle = document.querySelector(":root");

const useSketchfabViewer = () => {
  const [api, setApi] = useState();
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const [progressFactor, setProgressFactor] = useState("0");

  // const ViewerIframe4 = (
  //   <iframe
  //     ref={viewerIframeRef}
  //     src=""
  //     className="api-iframe-model"
  //     id="modelNissan"
  //     title="Your Configurator"
  //     style={{ border: 0 }}
  //     allow="vr; autoplay; fullscreen"
  //   ></iframe>
  // );

  // useEffect(() => {
  //   // Initialize the viewer
  //   const frame = document.querySelector("#modelNissan");
  //   if (frame) {
  //     let enablePreload = true;
  //     if (isMobile) {
  //       enablePreload = false;
  //     }
  //     const client = new window.Sketchfab(viewerIframeRef.current);
  //     client.init(MODEL_UID, {
  //       success: onSuccess,
  //       error: () => {
  //         console.log("Viewer error");
  //       },
  //       camera: 0,
  //       preload: 0, //enablePreload
  //       autostart: 1,
  //       animation_autoplay: 0,
  //       annotation: 0,
  //       annotation_tooltip_visible: 0,
  //       annotations_visible: 0,
  //       ui_animations: 0,
  //       ui_controls: 0,
  //       ui_infos: 0,
  //       ui_stop: 0,
  //       ui_watermark_link: 0,
  //       ui_watermark: 0,
  //       ui_help: 0,
  //       ui_vr: 0,
  //       ui_ar: 0,
  //       ui_hint: 0,
  //       ui_ar_help: 0,
  //       ui_ar_qrcode: 0,
  //       ui_inspector: 0,
  //       ui_settings: 0,
  //       ui_annotations: 0,
  //       ui_fullscreen: 0,
  //       transparent: 0,
  //       ui_loading: 1,
  //       ui_color: "ff4f57",
  //       orbit_constraint_pan: 1,
  //       orbit_constraint_zoom_in: 5.55,
  //       orbit_constraint_zoom_out: 80,
  //     });
  //   }
  // }, []);

  useEffect(() => {
    document.body.classList.add("loader-bg");
    const frame = document.querySelector("#modelNissan");
    let enablePreload = true;
    if (isMobile) {
      enablePreload = false;
    }
    if (frame) {
      // eslint-disable-next-line no-undef
      const client = new Sketchfab(frame);

      client.init(MODEL_UID, {
        success: onSuccess,
        error: () => {
          //console.log("Viewer error");
        },
        preload: enablePreload, //enablePreload
        dnt: 1,
        autostart: 1,
        animation_autoplay: 0,
        annotation: 0,
        annotation_tooltip_visible: 0,
        annotations_visible: 0,
        ui_animations: 0,
        ui_controls: 0,
        ui_infos: 0,
        ui_stop: 0,
        ui_watermark_link: 0,
        ui_watermark: 0,
        ui_help: 0,
        ui_ar: 0,
        ui_hint: 0,
        ui_ar_help: 0,
        ui_ar_qrcode: 0,
        ui_inspector: 0,
        ui_settings: 0,
        ui_annotations: 0,
        ui_fullscreen: 0,
        transparent: 0,
        ui_loading: 0,
        ui_color: "ff4f57",
        //orbit_constraint_pan: 1,
        orbit_constraint_pan: 1,
        orbit_constraint_zoom_in: 5.55,
        orbit_constraint_zoom_out: 80,
        //orbit_constraint_zoom_out: 77,
        //scrollwheel: 1,
        //double_click: 1,
        //orbit_constraint_pitch_up: 0.36,
        //orbit_constraint_pitch_down: 0.07,
      });
    }
  }, []);

  function onSuccess(api) {
    api.start(
      api.addEventListener("textureLoadProgress", (factor) => {}),
      api.addEventListener("modelLoadProgress", (factor) => {
        //modelLoadProgress
        const progress = Math.round(factor.progress * 100);
        setProgressFactor(progress);
        setTimeout(() => {
          rootStyle.style.setProperty("--bg-loader", `${100 - progress}`);
          rootStyle.style.setProperty("--bg-size", `${progress}%`);
        }, 700);
      }),
      api.addEventListener("viewerready", function () {
        api.setCameraEasing("easeLinear");

        api.getMaterialList(function (error, materials) {
          //console.log("materials", materials);
          if (!error) {
            const plasticMaterial = materials.find(
              (material) => material.name === "carpaint_body"
            );
            plasticMaterial.channels.AlbedoPBR.color = [
              0.137255, 0.137255, 0.137255,
            ];
            api.setMaterial(plasticMaterial, () => {
              //console.log("Updated paint color");
            });
          }
        });

        api.getSceneGraph(function (err, result) {
          if (err) {
            //console.log("Error getting nodes");
            return;
          }

          // console.log("getSceneGraph", result);
          // const arr = result.children[0].children;
          // arr.map((val) => {
          //   console.log(val.name, "=", val.instanceID);
          // });
          api.hide(1593); // Exterior_SkySphere/CarShadow [1593]
          //api.hide(1640); // SM_Studio_Shadow [1640]

          api.hide(1426); // Bed Tent
          // api.hide(406); // Trunk
          api.hide(1127); //SM_Bedliner [1127]

          //api.hide(725); // Wheel 1
          api.hide(1492); // SM_Wheel_2 [1492]

          api.hide(1004); //  SM_Black_Tube_Step_Rails [1004]
          api.hide(1036); // SM_Off_Road_Step_Rails [1036]
          api.hide(1064); // SM_Yakima_SKi_Model [1064]

          api.hide(1113); // SM_Windows_Deflector [1113]
          api.hide(1392); // SM_Frontier [1392] // Sport bar

          api.hide(1206); // SM_Yakima_Hold_Up_Bike_1 [1206]
          api.hide(1223); // SM_Yakima_Hold_Up_Bike_2 [1223]
          api.hide(1240); // SM_Yakima_HitchSKi [1240]
          api.hide(1254); // SM_Yakima_Hold_Up [1254]
          api.hide(1271); // SM_Fixed_Bed_Extender [1271]
          api.hide(1289); //  SM_Sliding_Bed_Extender[1289];

          api.hide(1375); // SM_Bed_Assist_Grip [1375]
          api.hide(1358); // SM_Weight_Distributing_Hitch_Ball [1358]
          api.hide(1341); // SM_Hitch_Ball_Mount [1341]
          //api.hide(1324); //  SM_Tow_Hitch_Receiver [1324]
          api.hide(1307); // SM_Rear_Bumper_Step [1307]

          // api.hide(); //
          // api.hide(); //
        });
        // api.setTextureQuality("hd", function (err) {
        //   if (!err) {
        //     window.console.log("Texture quality set to high definition");
        //   }
        // });
        setTimeout(() => setApi(api), 300);
        setTimeout(() => setIsModelLoaded(true), 1500);
      })
    );
  }
  if (isModelLoaded) {
    document.body.classList.remove("loader-bg");
  }
  const ViewerIframe = (
    <>
      {isModelLoaded ? <></> : <Preloader />}
      <iframe
        className="api-iframe-model"
        id="modelNissan"
        title="Your Configurator"
        style={{ border: 0 }}
        allow="vr; autoplay; fullscreen"
      ></iframe>
    </>
  );

  return [ViewerIframe, api];
};

export const Model = ({ apiRef }) => {
  const [ViewerIframe, api] = useSketchfabViewer();
  apiRef.current = api;
  return ViewerIframe;
};
