import { ReactComponent as Loader } from "./loader.svg";
import "./Button.css";

/*
 <button
          className="footer-nav-2-button btn-getQuote"
          form="formRequestQuote"
          id="GetQuoteBtn"
          onClick={onClickGetQuote}
        >
          <span>Get Your Quote</span>
          <MdNavigateNext />
        </button>

*/
const Button = ({ onSubmit, text, loading = false, disabled }) => {
  return (
    <button
      className="submit-btn footer-nav-2-button btn-getQuote"
      form="formRequestQuote"
      id="GetQuoteBtn"
      onClick={onSubmit}
      disabled={disabled}
      type="submit"
    >
      {!loading ? text : <Loader className="spinner" />}
    </button>
  );
};

export default Button;
