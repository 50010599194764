import React from "react";

import PropTypes from "prop-types";
import { formatPrice } from "../../utils";
// Styles
import "./Settings.css";
// Components
import Option from "../Option";
import { Fade } from "react-awesome-reveal";
import Modal from "react-modal";
Modal.setAppElement("#root");
/*
 * TODO: Refactor Editor to leverage React hooks
 *
 * Requirements:
 * - store selectedOptions in React state using the useState hook
 * - initialize state using lazy initialization
 * - use other React hooks if needed
 *
 */
const Settings = ({
  config = null,
  settings = null,
  onSelectOption = () => null,
  onSelectOptionModel = () => null,
  onChangeOption = () => null,
  selectedSubItem = 0,
  models = null,
}) => {
  const apiRef = config?.sketchFab?.apiRef;
  //

  const textures = [
    {
      url: "https://i.imgur.com/K9Hgbak.jpg",
      name: "Charcoal Leather",
      uid: null,
    },
    {
      url: "https://i.imgur.com/JEnc5HD.jpg",
      name: "Charcoal Cloth with Lava Red Stitch",
      uid: null,
    },
  ];

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [modalIsOpen4, setIsOpen4] = React.useState(false);
  const selectedModel = models?.find((model) => model?.key === config?.model);
  const selectedStyling = selectedModel?.styling?.find(
    (styling) => styling.value === config?.styling
  );
  const selectedStorage = selectedModel?.storage?.find(
    (storage) => storage.value === config?.storage
  );
  const selectedTowings = selectedModel?.towings?.find(
    (towings) => towings.value === config?.towings
  );
  console.log(selectedStorage?.selector);

  const hidePart = (value) => {
    //console.log(value);
    // Paint
    if (
      value === 11 ||
      value === 12 ||
      value === 13 ||
      value === 14 ||
      value === 15 ||
      value === 16 ||
      value === 17 ||
      value === 18 ||
      value === 19
    ) {
      apiRef.current.getMaterialList((err, materials) => {
        const plasticMaterial = materials.find(
          (material) => material.name === "carpaint_body"
        );
        //[0.02518685962736162, 0.04091519690685319, 0.0202885630566524];
        if (value === 11) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.32314320911295075, 0, 0,
          ];
        } else if (value === 12) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.17788841598362914, 0, 0,
          ];
        } else if (value === 13) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.02518685962736162, 0.04091519690685319, 0.0202885630566524,
          ];
        } else if (value === 14) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.0027317428519395377, 0.04518620438567554, 0.15292615199615017,
          ];
        } else if (value === 15) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.14702726649759498, 0.10702310297826759, 0.07618538148130782,
          ];
        } else if (value === 16) {
          plasticMaterial.channels.AlbedoPBR.color = [0, 0, 0];
        } else if (value === 17) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.137255, 0.137255, 0.137255,
          ];
        } else if (value === 18) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.1912016827407914, 0.1912016827407914, 0.1912016827407914,
          ];
        } else if (value === 19) {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.6938717612919899, 0.7083757798916865, 0.7230551127219691,
          ];
        } else {
          plasticMaterial.channels.AlbedoPBR.color = [
            0.137255, 0.137255, 0.137255,
          ];
        }

        apiRef.current.setMaterial(plasticMaterial, () => {
          //console.log("Updated chair color");
        });
      });
    }
    // Styling
    else if (value === 14911279) {
      apiRef.current.hide(1392);
      apiRef.current.hide(1113);
    } else if (value === 1392) {
      // Conflict Sport Bar x Bed Tent
      if (selectedStorage?.selector === 1426) {
        setIsOpen2(true);
      } else {
        apiRef.current.show(value);
        apiRef.current.hide(1113);
      }
    } else if (value === 1113) {
      apiRef.current.show(value);
      apiRef.current.hide(1392);
    }
    // Wheels
    else if (value === 725) {
      apiRef.current.hide(1492);
      apiRef.current.show(value);
    } else if (value === 1492) {
      apiRef.current.hide(725);
      apiRef.current.show(value);
    }
    // Storage
    else if (
      value === 1383000 ||
      value === 1206 ||
      value === 1064 ||
      value === 1223 ||
      value === 1426 ||
      value === 1375 ||
      value === 1271 ||
      value === 1289 ||
      value === 1127 ||
      value === 1409
    ) {
      apiRef.current.hide(1206);
      apiRef.current.hide(1064);
      apiRef.current.hide(1223);
      apiRef.current.hide(1426);
      apiRef.current.hide(1375);
      apiRef.current.hide(1271);
      apiRef.current.hide(1289);
      apiRef.current.hide(1127);
      apiRef.current.hide(1409);

      apiRef.current.hide(1240);
      apiRef.current.hide(1254);
      apiRef.current.hide(1127);
      apiRef.current.show(406);
      if (value === 1206) {
        // Conflict Affiliated 01 x Towings
        if (
          selectedTowings?.selector === 1358 ||
          selectedTowings?.selector === 1341
        ) {
          setIsOpen3(true);
        }
        apiRef.current.show(1240);
        apiRef.current.show(1206);
      } else if (value === 1064) {
        // Conflict Affiliated 02 x Towings
        if (
          selectedTowings?.selector === 1358 ||
          selectedTowings?.selector === 1341
        ) {
          setIsOpen3(true);
        }
        apiRef.current.show(1240);
        apiRef.current.show(1064);
      } else if (value === 1223) {
        // Conflict Affiliated 03 x Towings
        if (
          selectedTowings?.selector === 1358 ||
          selectedTowings?.selector === 1341
        ) {
          setIsOpen3(true);
        }
        apiRef.current.show(1206);
        apiRef.current.show(1254);
        apiRef.current.show(1223);
      } else if (value === 1426) {
        // Conflict Sport Bar x Bed Tent 2
        if (selectedStyling?.selector === 1392) {
          setIsOpen(true);
        } else {
          apiRef.current.hide(406);
          apiRef.current.show(1127);
          apiRef.current.show(1426);
        }
      } else if (value === 1375) {
        apiRef.current.hide(406);
        apiRef.current.show(1127);
        apiRef.current.show(1375);
      } else if (value === 1271) {
        apiRef.current.hide(406);
        apiRef.current.show(1127);
        apiRef.current.show(1271);
      } else if (value === 1289) {
        apiRef.current.hide(406);
        apiRef.current.show(1127);
        apiRef.current.show(1289);
      } else if (value === 1127) {
        apiRef.current.hide(406);
        apiRef.current.show(1127);
      } else if (value === 1409) {
        apiRef.current.hide(406);
        apiRef.current.show(1127);
        apiRef.current.show(1409);
      } else {
        apiRef.current.show(406);
      }
    }
    // Running Bords
    else if (value === 12251203) {
      apiRef.current.hide(1036);
      apiRef.current.hide(1004);
    } else if (value === 1225) {
      apiRef.current.hide(1036);
      apiRef.current.hide(1004);
      apiRef.current.show(value);
    } else if (value === 1004) {
      apiRef.current.hide(1036);
      apiRef.current.hide(1004);
      apiRef.current.show(value);
    }
    // interiorColors
    else if (value === 22202) {
      apiRef.current.getMaterialList(function (err, materials) {
        console.log("materials", materials);
        let material = materials[4];
        apiRef.current.addTexture(textures[0].url, (err, uid) => {
          if (!err) {
            textures[0].uid = uid;
            let materialToUpdate = material;
            materialToUpdate.channels.AlbedoPBR.texture.uid = textures[0].uid;
            materialToUpdate.channels.AlbedoPBR.enable = true;
            apiRef.current.setMaterial(materialToUpdate, () => {
              //window.console.log("Updated material.");
            });
          }
        });
      });
    } else if (value === 22203) {
      apiRef.current.getMaterialList(function (err, materials) {
        let material = materials[4];
        apiRef.current.addTexture(textures[1].url, (err, uid) => {
          if (!err) {
            textures[1].uid = uid;
            let materialToUpdate = material;
            materialToUpdate.channels.AlbedoPBR.texture.uid = textures[1].uid;
            materialToUpdate.channels.AlbedoPBR.enable = true;
            apiRef.current.setMaterial(materialToUpdate, () => {
              //window.console.log("Updated material.");
            });
          }
        });
      });
    } // Towings
    else if (value === 1324) {
      apiRef.current.hide(1358);
      apiRef.current.hide(1341);
      apiRef.current.show(value);
    } else if (value === 1358) {
      // Conflict Affiliated x Towings 01
      if (
        selectedStorage?.selector === 1206 ||
        selectedStorage?.selector === 1064 ||
        selectedStorage?.selector === 1223
      ) {
        setIsOpen4(true);
      }
      apiRef.current.hide(1240);
      apiRef.current.hide(1341);
      apiRef.current.show(1324);
      apiRef.current.show(value);
    } else if (value === 1341) {
      // Conflict Affiliated x Towings 02
      if (
        selectedStorage?.selector === 1206 ||
        selectedStorage?.selector === 1064 ||
        selectedStorage?.selector === 1223
      ) {
        setIsOpen4(true);
      }
      apiRef.current.hide(1240);
      apiRef.current.hide(1358);
      apiRef.current.show(1324);
      apiRef.current.show(value);
    } else {
      apiRef.current.show(value);
    }
  };
  const selectedOptions = settings?.reduce(
    (acc, setting) => ({
      ...acc,
      [setting.prop]:
        setting.options.find(
          (option) => option.value === config[setting.prop]
        ) ?? [],
    }),
    {}
  );

  function closeModal() {
    setIsOpen(false);
    setIsOpen2(false);
  }
  function closeModal3() {
    onSelectOption("storage", "nstorage");
    hidePart(1383000);
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
  }
  function closeModal4() {
    apiRef.current.hide(1358);
    apiRef.current.hide(1341);
    setIsOpen(false);
    setIsOpen2(false);
    setIsOpen3(false);
    setIsOpen4(false);
    onSelectOption("towings", "towing_1");
  }
  function removeSportsBarModal() {
    apiRef.current.hide(1392);
    apiRef.current.hide(406);
    apiRef.current.show(1127);
    apiRef.current.show(1426);
    setIsOpen(false);
    setIsOpen2(false);
    onSelectOption("styling", "nstyle");
  }
  function removeBedTentModal() {
    apiRef.current.hide(1127);
    apiRef.current.hide(1426);
    apiRef.current.show(406);
    apiRef.current.show(1392);
    setIsOpen(false);
    setIsOpen2(false);
    onSelectOption("storage", "nstorage");
  }
  // removeAffiliatedModal
  function removeTowingModal() {
    apiRef.current.hide(1358);
    apiRef.current.hide(1341);
    // apiRef.current.show(1491);
    setIsOpen3(false);
    onSelectOption("towings", "towing_1");
  }
  function removeAffiliatedModal() {
    apiRef.current.hide(1206);
    apiRef.current.hide(1064);
    apiRef.current.hide(1223);
    apiRef.current.hide(1254);
    apiRef.current.hide(1371);
    setIsOpen4(false);
    onSelectOption("storage", "nstorage");
  }

  const customStyles = {
    content: {
      top: "118px",
      left: "auto",
      right: "0",
      borderRadius: "0",
      border: "none",
      bottom: "auto",
      padding: "20px 40px",
      width: "280px",
      height: "100vh",
      background: "#2d2f37",
      transform: "translateX(0%)",
      transition: "transform 300ms ease-out 150ms",
    },
  };
  config.sketchFab = { apiRef };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Conflict Sport Bar with Bed Tent Modal"
      >
        <Fade>
          <h2 className="requirement-title">REQUIREMENT</h2>
          <div className="requirement-text">
            <b>Sports Bar</b> must be removed to show <b>Bed Tent</b> .
          </div>
          <form className="requirement-form">
            <button className="requirement-btn" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="requirement-btn requirement-btn-2"
              onClick={removeSportsBarModal}
            >
              Remove
            </button>
          </form>
        </Fade>
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Conflict Bed Tent with Sport Bar Modal"
      >
        <Fade>
          <h2 className="requirement-title">REQUIREMENT</h2>
          <div className="requirement-text">
            <b>Bed Tent</b> must be removed to show <b>Sports Bar</b>.
          </div>
          <form className="requirement-form">
            <button className="requirement-btn" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="requirement-btn requirement-btn-2"
              onClick={removeBedTentModal}
            >
              Remove
            </button>
          </form>
        </Fade>
      </Modal>
      <Modal
        isOpen={modalIsOpen3}
        onRequestClose={closeModal3}
        style={customStyles}
        contentLabel="Conflict Towing with Affiliated Modal"
      >
        <Fade>
          <h2 className="requirement-title">REQUIREMENT</h2>
          <div className="requirement-text">
            <b>{selectedTowings?.label}</b> must be removed to show{" "}
            <b>{selectedStorage?.label}</b>.
          </div>
          <form className="requirement-form">
            <button className="requirement-btn" onClick={closeModal3}>
              Cancel
            </button>
            <button
              className="requirement-btn requirement-btn-2"
              onClick={removeTowingModal}
            >
              Remove
            </button>
          </form>
        </Fade>
      </Modal>
      <Modal
        isOpen={modalIsOpen4}
        onRequestClose={closeModal4}
        style={customStyles}
        contentLabel="Conflict Towing with Affiliated Modal"
      >
        <Fade>
          <h2 className="requirement-title">REQUIREMENT</h2>
          <div className="requirement-text">
            <b>{selectedStorage?.label}</b> must be removed to show{" "}
            <b>{selectedTowings?.label}</b>.
          </div>
          <form className="requirement-form">
            <button className="requirement-btn" onClick={closeModal4}>
              Cancel
            </button>
            <button
              className="requirement-btn requirement-btn-2"
              onClick={removeAffiliatedModal}
            >
              Remove
            </button>
          </form>
        </Fade>
      </Modal>
      <div className="settings">
        {settings?.map((setting, i) => {
          if (
            !setting.options ||
            setting.options.length === 0 ||
            (selectedSubItem !== i && selectedSubItem !== setting.ref)
          ) {
            return null;
          }
          return (
            <Fade key={i}>
              <div className="settings-group">
                <div
                  id="list-item-ink"
                  className={`settings-options settings-options-${setting.type}`}
                >
                  {setting.options.map((option, i) => (
                    // <Fade key={i} cascade damping={0.1}>
                    <Option
                      {...option}
                      key={option.selector}
                      type={setting.type}
                      price={formatPrice(option.price)}
                      active={config?.[setting.prop] === option.value}
                      onSelectOption={(value) =>
                        onSelectOption(setting.prop, value)
                      }
                      onSelectOptionModel={(value) => hidePart(value)}
                      required={option.required}
                    />
                    // </Fade>
                  ))}
                </div>

                {setting.type !== "text" ? (
                  <div className="settings-group-label">
                    <span>{selectedOptions?.[setting.prop]?.label}</span>
                    <span className="price">
                      {formatPrice(selectedOptions?.[setting.prop]?.price)}
                    </span>
                  </div>
                ) : null}
                {selectedOptions?.[setting.prop]?.benefits ? (
                  <div className="settings-group-benefits">
                    <p>
                      Model {config.model.toUpperCase()}{" "}
                      {selectedOptions[setting.prop].label} includes:
                    </p>
                    <ul>
                      {selectedOptions?.[setting.prop]?.benefits?.map(
                        (benefit, i) => (
                          <li key={i}>{benefit}</li>
                        )
                      )}
                    </ul>
                  </div>
                ) : null}
                {setting.disclaimer_2 ? (
                  <p className="settings-group-disclaimer">
                    {setting.disclaimer_2}
                  </p>
                ) : null}
              </div>
            </Fade>
          );
        })}
      </div>
    </>
  );
};

Settings.propTypes = {
  config: PropTypes.object,
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      prop: PropTypes.string,
      options: PropTypes.array,
    })
  ),
  onSelectOption: PropTypes.func,
  onChangeOption: PropTypes.func,
};

export default Settings;
