import React, { useState } from "react";
import PropTypes from "prop-types";
import { formatPrice } from "../../utils";

// Styles
import "./Footer.css";
// Icons
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";

/*
 * TODO
 *
 * Requirements:
 * - use React hooks if needed
 * - use performance optimization if needed
 *
 */
const Footer = ({
  totalPrice = 0,
  disablePrev = true,
  disableNext = true,
  showQuote = false,
  activeStudioBtn = true,
  onClickPrev = () => null,
  onClickNext = () => null,
  onClickStudio = () => null,
  onClickStudio2 = () => null,
  onClickGetQuote = () => null,
  onClickSummary = () => null,
  nextStep = null,
  prevStep = null,
}) => {
  return (
    <div className="footer">
      <div className="footer-inner-lg">
        <div className="footer-nav ">
          {activeStudioBtn ? (
            <>
              <button className="footer-nav-2-button " onClick={onClickStudio}>
                <span>Studio</span>
              </button>
              <button
                className="footer-nav-2-button btn-2"
                onClick={onClickStudio2}
              >
                <span>Outdoor</span>
              </button>
            </>
          ) : (
            <>
              <button
                className="footer-nav-2-button btn-2"
                onClick={onClickStudio}
              >
                <span>Studio</span>
              </button>
              <button className="footer-nav-2-button " onClick={onClickStudio2}>
                <span>Outdoor</span>
              </button>
            </>
          )}
        </div>
      </div>

      {!showQuote ? (
        <>
          <div>
            <div className="footer-inner footer-inner-sm">
              <div className="footer-nav footer-nav-2" id="envBtns">
                {activeStudioBtn ? (
                  <>
                    <button
                      className="footer-nav-2-button "
                      onClick={onClickStudio}
                    >
                      <span>Studio</span>
                    </button>
                    <button
                      className="footer-nav-2-button btn-2"
                      onClick={onClickStudio2}
                    >
                      <span>Outdoor</span>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="footer-nav-2-button btn-2"
                      onClick={onClickStudio}
                    >
                      <span>Studio</span>
                    </button>
                    <button
                      className="footer-nav-2-button "
                      onClick={onClickStudio2}
                    >
                      <span>Outdoor</span>
                    </button>
                  </>
                )}
              </div>
              <div className="footer-nav footer-nav-1">
                {prevStep ? (
                  <button onClick={onClickPrev} disabled={disablePrev}>
                    <MdNavigateBefore />
                    <span>
                      <small>Prev</small>
                      {prevStep}
                    </span>
                  </button>
                ) : (
                  <button onClick={onClickPrev} disabled={disablePrev}></button>
                )}
                <button className="total-block-btn" onClick={onClickSummary}>
                  <h5 className="total-block">
                    <small className="total">TOTAL</small>
                    {formatPrice(totalPrice, "-")}
                  </h5>
                </button>
                {nextStep ? (
                  <button onClick={onClickNext} disabled={disableNext}>
                    <span>
                      <small>Next</small>
                      {nextStep}
                    </span>
                    <MdNavigateNext />
                  </button>
                ) : (
                  <button onClick={onClickNext} disabled={disableNext}></button>
                )}
              </div>
            </div>
          </div>

          <div className="footer-inner footer-inner-lg">
            <div className="footer-nav footer-nav-1">
              {prevStep ? (
                <button onClick={onClickPrev} disabled={disablePrev}>
                  <MdNavigateBefore />
                  <span>
                    <small>Prev</small>
                    {prevStep}
                  </span>
                </button>
              ) : (
                <button onClick={onClickPrev} disabled={disablePrev}></button>
              )}

              {nextStep ? (
                <button onClick={onClickNext} disabled={disableNext}>
                  <span>
                    <small>Next</small>
                    {nextStep}
                  </span>
                  <MdNavigateNext />
                </button>
              ) : (
                <button onClick={onClickNext} disabled={disableNext}></button>
              )}
            </div>
            <button className="total-block-btn" onClick={onClickSummary}>
              <h5 className="total-block">
                <small className="total">TOTAL</small>
                {formatPrice(totalPrice, "-")}
              </h5>
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

Footer.propTypes = {
  totalPrice: PropTypes.number,
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool,
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,

  onClickStudio: PropTypes.func,
  onClickStudio2: PropTypes.func,

  onClickGetQuote: PropTypes.func,
};

export default Footer;
